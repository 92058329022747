<template>
    <div style="margin:0 10px">
        <router-view></router-view> 
        <div class="card">
            <!-- search -->
            <div class="search d-flex a-center j-sb">
                <div class="d-flex a-center">
                    <div style="width:160px;margin-right:10px;">
                        <el-input type="text" v-model="query.name" placeholder="资源名称"></el-input>  
                    </div>
                    <el-button type="primary" @click="init">查询</el-button>
                    <el-button type="primary" @click="$router.push({name:'source_add'})">+ 添加</el-button>    
                </div>

            </div>

            <!-- handle -->
            <div class="handle"></div>

            <!-- table -->
            <div class="table" style="margin-top:20px;">
                <el-table
                    :data="tableData"
                    style="width: 100%;"
                    v-loading="loading"
                    element-loading-background="rgba(255,255,255, 0.6)"
                    :header-cell-style="{background:'#f8f8f9',color:'#606266',borderBottom:'1px solid #e8eaec',padding:'8px 0',fontWeight:'700'}"
                >
                    <el-table-column prop="name" label="资源名称" align="center"></el-table-column>
                    <el-table-column prop="resourceUri" label="资源内容" align="center"></el-table-column>
                    <el-table-column prop="" label="操作" align="center" width="180">
                        <template slot-scope="scope">
                            <router-link :to="{name:'source_edit',query:scope.row}" style="color: #18b1ee;">编辑</router-link>
                            <el-popconfirm
                                style="margin-left: 10px;"
                                title="是否确定删除？"
                                @confirm="handleDel(scope.row)"
                            >
                                <el-button type="text" slot="reference" size="small">删除</el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- page -->
                <div style="margin-top:20px;text-align:center">
                    <el-pagination
                        background
                        layout="total, prev, pager, next, sizes"
                        :total="total"
                        :page-size="query.limit"
                    >
                    </el-pagination>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import {lists,del} from '@/request/api/source.js'
export default {
    data(){
        return {
            tableData: [],
            query:{name:''},
            total:0 , //列表总数
            loading:false,
            isEdit:false ,//编辑状态
        }
    },
    watch:{
        $route(val ,old){
            //返回刷新
            if(val.name=='source') {
                this.init()
            }
        }
    },
    created(){
        this.init()
    },

    methods:{
        init(){
            lists(this.query).then(res=>{
                this.tableData = res.data.list
                this.total = res.data.total
            })
        },
        //删除
        handleDel(data){
            del({ids:[data.id]}).then(res=>{
                if(res.code!=0) return this.$notify.error({title:'错误',message: res.msg});
                this.init()
                this.$notify({title: '成功',message: res.msg,type: 'success'});
            })
        },
    }
}
</script>


<style scoped lang="less">


</style>

